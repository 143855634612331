.palanquita {
  width: fit-content;
}

.palanquita__button {
  // Variables
  --height: 24px;
  --width : 40px;
  --track-color: #999;
  --track-color--is-active: var(--color--primary);
  --pin-color: #eee;

  // Position
  position: relative;
  // Box Model
  height : var(--height);
  width  : var(--width);
  padding: 0;
  // Other
  background-color: var(--track-color);
  border: none;
  border-radius: calc(var(--height) / 2);
  box-shadow   : inset 0 1px 1px 0 #555,
                 0 1px 0 1px white;
  cursor    : pointer;
  transition: background-color 0.2s ease-out;

  &:disabled {
    cursor: not-allowed;
  }

  &:before {
    content: '';

    // Variables
    --margin: 2px;
    --dimensions: 20px;
    // Position
    position: absolute;
    left    : var(--margin);
    top     : var(--margin);
    z-index : 20;
    // Box Model
    display: block;
    height : var(--dimensions);
    width  : var(--dimensions);
    // Other
    background-color: var(--pin-color);
    border-radius   : 50%;
    box-shadow      : 0 2px 3px -1px #333;
    transition      : .3s ease-out;
  }

  &.is--active {
    // Other
    background-color: var(--track-color--is-active);
  }

  &.is--active:before {
    // Position
    left: 18px;
  }
}