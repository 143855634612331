.listado-de-pedidos {
  // Box Model
  margin : 0;
  padding: 0;

  // List
  list-style-type: none;
}
.listado-de-pedidos__item {
  // Box Model
  margin-bottom : var(--base-line-height);
  padding-bottom: var(--base-line-height-halved);
}
.control--pedido {
  @extend %button;

  // Box Model
  width: 100%;
  padding: var(--base-line-height-halved);
}
.listado-de-pedidos__pedido {
  // Flexbox
  display       : flex;
  flex-direction: column;
  align-items   : flex-start;
}
.pedido__wrapper--information {
  // Flexbox
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  justify-content: space-between;

  // Box Model
  width : 100%;
  margin-block-end: var(--base-line-height);
}
.layout--wrapper {
  .pedido__wrapper--information & {
    // Variables
    $spacing: calc(var(--base-line-height-halved) / 2);
    
    // Box Model
    padding: 0 0 $spacing;
    margin : 0 0 $spacing;

    // Flexbox
    display        : flex;
    flex-direction : row;
    justify-content: space-between;

    // Box Model
    width: 100%;

    // Other
    box-shadow: 0 0.1rem 0 0 #0003;
  }
}
.pedido__codigo {
  // Typography
  color      : var(--color--accent-shade);
  font-size  : calc(var(--base-font-size) + 0.2rem);
  font-weight: 600;
}
.pedido__fecha {
  // Typography
  color      : var(--color--accent-shade);
  font-size  : var(--base-font-size);
  font-weight: 600;
}
.pedido__lista-de-productos {
  // CSS Counters Reference
  // https://blog.logrocket.com/styling-numbered-lists-with-css-counters/
  // https://css-tricks.com/custom-list-number-styling/
  
  // Box Model
  margin : 0;
  padding: 0;

  // Typography
  text-align: left;

  // List
  list-style-type: none;
}

.pedido__estados {
  // Variable
  --color-del-estado: var(--color--accent);
  // Flexbox
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
}
.mapa-de-estados {
  .pedido__estados & {
    // Flexbox
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
  }
}
.marcador-de-estado {
  .mapa-de-estados & {
    // Variables
    --dimensiones   : 1.6rem;
    --ancho-conector: 1.2rem;
    --alto-conector : 0.4rem;
    // Position
    position: relative; // Creating positioning context
    // Flexbox
    display: flex;
    // Box Model
    height: var(--dimensiones);
    width : var(--dimensiones);
    margin-inline-end: 1.2rem;
    // Other
    background-color: #cccc;
    border-radius   : 50%;

    &.is--actual {
      // Variables
      --dimensiones: 2rem;
      // Other
      background-color: var(--color-del-estado);
    }

    &:not(.is--usado, .is--actual)::before {
      content: '';
      // Variables
      --dimensiones: 0.8rem;
      // Flexbox
      margin: auto;
      // Box Model
      height: var(--dimensiones);
      width : var(--dimensiones);
      // Other
      background-color: #f6f6f6;
      border-radius   : 50%;
      z-index         : 50;
    }
    &:not(:last-child)::after {
      content: '';
      // Position
      position: absolute;
      right   : calc(var(--ancho-conector) * -1);
      top     : calc((var(--dimensiones) / 2) - (var(--alto-conector) / 2));
      // Box Model
      display: block;
      height : var(--alto-conector);
      width  : var(--ancho-conector);
      // Other
      background-color: #cccccc;
    }
  }
}
.pedido__estado {
  // Box Model
  margin-block-start: 8px;
  padding: 0.2rem var(--base-line-height-halved);
  width  : fit-content;
  // Other
  background-color: var(--color-del-estado);
  border-radius   : 0.4rem;
}
*[class*="icon--"] {
  .pedido__estado & {
    // Box Model
    margin-right: var(--base-line-height-halved);

    // Typography
    color: var(--color--accent-shade);
  }
}