%select {
    /**
    | Reset
    | =====
    | Reset based on information found at:
    | https://moderncss.dev/custom-select-styles-with-pure-css/
    |
    **/
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border          : none;
    padding         : 0 var(--base-line-height-halved) 0 0;
    margin          : 0;
    width           : 100%;
    font-family     : inherit;
    font-size       : inherit;
    cursor          : inherit;
    line-height     : inherit;
    outline         : none;

    /* Grid */
    grid-area: select;
}
%wrapper--select {
    /* Variables */
    --arrow--color: black;

    /* Grid */
    display            : grid;
    align-items        : center;
    justify-items      : center;
    grid-template-areas: "select"; // Setting up a grid context

    &::after {
        content: '';

        /* Box Model */
        display: inline-block;
        width  : 0.7rem; // MAGIC NUMBER
        height : 0.5rem; // MAGIC NUMBER

        /* Other */
        background-color: var(--arrow--color);
        /* We create a triangle shape using clip-path */
        clip-path       : polygon(100% 0%, 0 0%, 50% 100%);
    }
}
/**
| POSITIONING THE ARROW
| =====================
| We're setting both the select and the pseudo-element
| that will play the part of the down-facing arrow
| to the same grid-area so they can overlap.
|
| After that, we'll set the pseudo-element to
| 'justify-self: end;' to align it to the end of the
| reading orientation for the grid.
|
**/
%select,
%wrapper--select::after {
    
    /* Grid */
    grid-area: select;
}
%wrapper--select::after {
    /* Grid */
    justify-self: end;
}