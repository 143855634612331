.formulario-comercio .comercio {

  .comercio--imagen .comercio--img {
    max-width: 200px;
    max-height: 200px;
  }
}

.producto-formulario {
}
.producto__descripcion {
  .formulario-comercio & {
    width: calc(400px + var(--base-line-height) * 4);
  }
}
.form__row {
  .formulario-comercio & {

  }
}
.input-combo {
  .formulario-comercio & {
    margin-inline-end: calc(var(--base-line-height) * 4);
  }
}
.comercio__direccion {
  .formulario-comercio & {
    width: calc(400px + var(--base-line-height) * 4);
  }
}
.comercio__descripcion {
  .formulario-comercio & {
    width: calc(400px + var(--base-line-height) * 4);
  }
}
.comercio--imagen {
  .formulario-comercio & {

    display: flex;
    flex-direction: column;

    label {
      // Variables
      --padding: 0.6rem;
      --top    : calc(var(--base-line-height-halved) * -1);
      --left   : calc(var(--base-line-height) - var(--padding));
      // Position
      top    : var(--top);
      left   : var(--left);
      z-index: 10;
      // Box Model
      padding: 0 var(--padding);
      // Typography
      color         : var(--color--primary);
      font-size     : 1.4rem;
      font-weight   : 600;
      letter-spacing: 1px;
    }

    .comercio--img {
      width: 100px;
      height: 100px;
      background-position: center;
      background-size    : cover;
      margin-inline-end: 60px;
      }
    }
}
.imagen-combo {
  display: flex;
  flex-direction: column;
  align-items: center;

}