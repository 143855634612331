.login-page {
  // Box Model
  margin : 0 0 var(--base-line-height);
  padding: 0 0 var(--base-line-height-halved);
  height: 100%;

  // Flexbox
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Typography
  color      : var(--color--accent-shade);
  font-size  : calc(var(--base-font-size) * 1.35);
  line-height: calc(var(--base-line-height) * 1.35);

  // Other
  background-color: var(--color--main-tint-shade);

}

.login-form {
  .login-page & {
    // Box Model
    // width: 300px;
    // height: 300px;

    // Other
    color: var(--color--text-bold);
    max-width: 80vw;
    background-color: var(--color--accent);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}

.input-combo label {
  .login-page & {
    background-color: var(--color--accent);
  }
}

.form__row {
  .login-page & {
    // Flexbox
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 12px;
    width: 100%;
  }
}

.login-button {
  .login-page & {
    width: 90px;
    justify-content: center;
  }
}