@use 'colors' as *;

@mixin app-variables() {
    /* DEFAULT COLOUR PALETTE */
    --color--primary         : #{$color--primary};
    --color--primary-shade   : #{$color--primary-shade};
    --color--accent          : #{$color--accent};
    --color--accent-tint     : #{$color--accent-tint};
    --color--accent-shade    : #{$color--accent-shade};
    --color--accent-shade-rgb: #{$color--accent-shade-rgb};
    --color--main-tint       : #{$color--main-tint};
    --color--main-tint-shade : #{$color--main-tint-shade};
    // Specific cases
    --color--text-bold       : var(--color--accent-shade);

    /* TYPOGRAPHY */
    --base-font-size         : 1.2rem;
    --base-line-height       : 1.5rem;
    --base-line-height-halved: 0.8rem;

    /* LAYOUT */
    --base--padding  : var(--base-line-height-halved);
    --app-bar--height: calc( var(--base-line-height) * 3 );
    --cta-bar--height: calc( var(--base-line-height) * 4 );
}