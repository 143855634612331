.admin-navegacion {
  // Box Model

  .landscape & {
    width: var(--admin-nav--width);
  }

  // Typography
  color: var(--color--accent-shade);

  // Other
  background-color: var(--color--main-tint-shade);

  &.is-collapsed {
    width: 50px;
  }

  .has--items-selected & {
    display: none;

    &.is--selected {
      display: table-row;
    }
  }
}

.admin-navegacion__item-list {
  // Box Model
  margin : 0;
  padding: 0;

  // List
  list-style: none;
}

.icon-button {
  .admin-navegacion__item & {
    // Variables
    --font-size: 1.5rem;

    // Box Model
    width: 100%;

    // Typography
    color: var(--color--accent-shade);

     &:hover {
      //  Other
      background-color: var(--color--accent);
    }

  }

  .admin-navegacion__item.is-selected & {
    // Box Model
    padding: var(--base-line-height)
             var(--base-line-height-halved)
             var(--base-line-height)
             var(--base-line-height);

    // Typography
    letter-spacing: 1px;

    // Other
    background-color: var(--color--accent);
    box-shadow      : inset var(--base-line-height-halved) 0 0 0 var(--color--accent-shade);
  }
}

.admin-navegacion__item {
  &:hover {
    background-color: var(--font-color);
  }
  &.is-selected {
    background-color: var(--font-color);
    color: var(--background-color);
  }
  .portrait & {
    .button__label {
      display: none;
    }
  }
}

.admin-navegacion__divisor {
  margin: 0.25em 0;
  border-top: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 1px 0 0 #fffa;
  height: 0px;
}