.app {
  // Flexbox
  display       : flex;
  flex-direction: column;
  // Box Model
  height    : 100vh;
  min-height: 100vh;
  // Positioning
  position: relative; // Creating a positioning context for children elements
  //Other
  background-color: var(--color--main-tint);
}
