.comercio__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 140px;
  margin-block-end: 20px;
  background-color: white;
  border-radius: 8px;
  .landscape & {
    cursor: pointer;
  }
}
.logo {
  .comercio__card & {
    // Positioning
    position: relative;
    // Box Model
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
    margin-block-start: 20px;
    // Other
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    border: 1px solid;
  }
}
.plus-icon {
  .comercio__card .logo & {
    display: none;
    border: 1px solid #3333;
    border-radius: 50%;
    font-size: 2em;
    padding: 3px;
    width: 24px;
    aspect-ratio: 1;
    bottom: -12px;
    position: absolute;
    /* text-align: center; */
    /* margin-inline: auto; */
    /* width: 100%; */
    left: calc(50% - 12px);
    background-color: var(--color--accent);
    color: var(--color--primary);
  }
  .comercio__card.tu-comercio .logo & {
    display: block;
  }
}
.info {
  .comercio__card & {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-start: 20px;
    text-align: center;
    overflow: hidden;

    .nombre {
      font-size: 18px;
      line-height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
    }

    .descripcion {
      // Reset
      padding: 0;
      margin: 0;
      // Typography
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      // Hack para multi-linea de 2 lineas maximo
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-inline-box;
    }
  }
}