.quiero-pedir__page {
  // POSITION HACK
  // TODO: Refactor screen padding out of main-container & into div.screen-name
/*
  position: relative;
  top     : calc(var(--base-line-height-halved) * -1);
  left    : calc(var(--base-line-height-halved) * -1);
   */
  // Box Model
  // height             : calc(100vh - var(--app-bar--height));
  // min-height         : calc(100vh - var(--app-bar--height));
  flex: 1;
  padding-block-start: calc(var(--base-line-height) * 4);
  width              : 100vw;
  // Other
  background-color: var(--color--main-tint-shade);
}
.layout--app-bar.menu-recepcion {
  background-color: var(--color--main-tint-shade);
  color: var(--color--primary);

  & .control--settings .button__icon {
    color: var(--color--primary);
  }

  & .control--settings .button__label {
    display: none;
  }

  .app-bar__logo,
  .control--settings.qrcode {
    display: flex;
  }
  .pibun-logo {
    color: var(--color--text-bold);
  }
}
.app-bar__controls {
  .menu-recepcion & {
    width: 100%;
  }
}
.quiero-pedir__titulo-container {
  // Flexbox
  display       : flex;
  flex-direction: column;
  // Box Model
  margin-block-end: 40px;
}
.titulo,
.subtitulo {
  .quiero-pedir__titulo-container & {
    // Box Model
    display      : inline-block;
    margin       : 0;
    margin-inline: auto;
    // Typography
    text-align: center;
  }
}
.titulo {
  .quiero-pedir__titulo-container & {
    @include font-poppins;
    // Typography
    color      : var(--color--text-bold);
    font-size  : 30px;
    font-weight: 500;
    line-height: 43px;

    .highlight {
      // Typography
      color: var(--color--primary);
      font-size  : 40px;
    }
  }
}
.subtitulo {
  .quiero-pedir__titulo-container & {
    @include font-caveat;
    // Box Model
    margin-block-start: var(--base-line-height);
    // Typography
    color      : var(--color--text-bold);
    font-size  : 3rem;
    line-height: 2.7rem;
  }
}
.quiero-pedir__controls {
  // Box Model
  width: 100%;
  // Flexbox
  display: flex;
  justify-content: space-around;
  margin-block-end: 20px;
}
.icon-button {
  .quiero-pedir__controls & {
    // Box Model
    height: 45px;
    width: 320px;
    // Flexbox
    justify-content: center;
    // Other
    background-color: var(--color--primary);
    border-radius: 4px;
    color: white;

    & .button__label {
      // Typography
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .landscape &:hover {
      background-color: var(--color--main-tint-shade);
      color: var(--color--primary);
      border: 2px solid var(--color--primary);
    }
  }
}
.quiero-pedir__comercios {
  margin-block-start: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.comercio__card {
  .quiero-pedir__comercios & {
    margin-block-end: 20px;
  }
}