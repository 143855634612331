.qr-reader {
  width: 100%;
  display: none;
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 300;
  background-color: rgba(0,0,0,0.8);

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.qr-reader--scanner {
  width: 100%;
  max-width: 320px;
  aspect-ratio: 1 / 1;
}
.qr-reader__control--cancelar {
  @extend %button--text-and-icon;
  @include button--default-shadow;
  width: 100%;
  max-width: 320px;
  justify-content: center;
  background-color: var(--color--accent-tint);
  color: var(--color--text-bold);
  // Typography
  font-size: 24px;

  .button__label {
    font: inherit;
  }
}
