.palanquita-combo,
.input-combo {
  // Position
  position: relative; // Positioning context
}
.palanquita-combo {
  display: flex;
}
label { // Refactor into CLASS-NAME
  .palanquita-combo &,
  .input-combo & {
    // Variables
    --padding: 0.6rem;
    --top    : var(--base-line-height);
    --left   : var(--base-line-height);
    // Typography
    color    : var(--color--accent-shade);
    font-size: 1.6rem;
    // Position
    position: absolute;
    top     : var(--top);
    left    : var(--left);
    z-index : 2;
    // Box Model
    padding: 0 var(--padding);
    // Other
    background-color: var(--color--main-tint);
    transition      : 0.2s ease;
  }
  .palanquita-combo &,
  .input-combo textarea:focus + &,
  .input-combo textarea.has-value + &,
  .input-combo input:focus + &,
  .input-combo input.has-value + & {
    // Variables
    --padding: 0.6rem;
    --top    : calc(var(--base-line-height-halved) * -1);
    --left   : calc(var(--base-line-height) - var(--padding));
    // Position
    top    : var(--top);
    left   : var(--left);
    z-index: 10;
    // Box Model
    padding: 0 var(--padding);
    // Typography
    color         : var(--color--primary);
    font-size     : 1.4rem;
    font-weight   : 600;
    letter-spacing: 1px;
  }
}
textarea,
input {
  .input-combo & {
    // Variables
    --line-height: 2rem;
    --font-size  : 1.6rem;
    // Position
    position: relative; // Allows for z-index
    z-index : 10;
    // Box Model
    box-shadow: 0 0 0.1rem 0.1rem var(--color--accent-shade);
    box-sizing: border-box;
    width: inherit;  // Hereda el width del input-combo
    // height    : calc(var(--base-line-height) * 3);
    padding   : var(--base-line-height);
    // Typography
    color      : var(--color--accent-shade);
    font-size  : var(--font-size);
    line-height: var(--line-height);
    // Other
    background-color: transparent;
    border          : none;
    border-radius   : 0.5rem;
    outline         : none;

    &:focus, &.has-value {
      // Other
      box-shadow: 0 0 0 0.2rem var(--color--primary-shade);
    }
  }
}
input {
  .input-combo & {
    height    : calc(var(--base-line-height) * 3);
  }
}
.palanquita {
  .palanquita-combo & {
    margin: auto;
  }
}