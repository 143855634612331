/**
TABLE OF CONTENTS
=================
Navigation Menu:
- nav-menu
-   nav-menu__information
-     location
-       location__business
-         icon--map-marker
-         location__business--name
-       location__seating
-     currency
-       currency__icon
-       currency__text
-     user
-       user__icon
-   nav-menu__list
-     nav-menu-item
-     nav-menu-item--moneda
-       moneda__label
-         label__icon
-     nav-menu-item--usuario
-       control--logout-and-login
-         button__icon
-         button__label
-   control--nav-menu-close
-     button__icon
**/

/**
REFERENCES
==========
https://dribbble.com/shots/1279414-App-detail
https://dribbble.com/shots/1310536-Menu-screen
http://theultralinx.com/2012/11/25-examples-menu-ui-design-inspiration/

**/

.nav-menu {
  /* Position */
  position: fixed;
  top     : var(--app-bar--height);
  z-index : 110;

  // Flexbox
  display        : flex;
  flex-direction : column;
  justify-content: space-between;

  /* Box Model */
  width : 100%;
  height: calc( 100vh - var(--app-bar--height) );

  /* Other */
  background-color: var(--color--main-tint-shade);
  box-shadow      : 0.1rem 0 0.5rem 0 #000a;
  transition      : top 0.5s ease-in-out;

  &.is--hidden {
    top: -100%;
  }

  .landscape & {
    max-width: 400px;
  }
}
.nav-menu__information {
  /* Box Model */
  padding: var(--base-line-height-halved) 0;

  // Typography
  color: var(--color--text-bold);

  /* Other */
  background-color: rgba(255, 255, 255, 0.6);

  // We set the spacing for internal items
  & > * {
    /* Box Model */
    margin: var(--base-line-height-halved);

    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }
}
.layout--icon-container {
  .nav-menu__information & {
    // Typography
    font-size: 1.4rem;
  }
}
// .location { }
// .location__business { }
.location__icon {
  /* Typography */
  color: var(--color--primary);
}
.location__business--name {
  /* Box Model */
  display: inline-block; /* Matching height to line-height */

  /* Typography */
  font-size     : 1.3rem;
  text-transform: uppercase;
}
.location__seating {
  /* Box Model */
  display    : inline-block; /* Matching height to line-height */
  margin-left: 0.3em; /* Margin simulates a &nbsp; */
}
// .currency { }
.currency__icon {
  /* Typography */
  color: var(--color--primary);
}
// .currency__text { }
// .user { }
.user__icon {
  /* Typography */
  color: var(--color--primary);
}
.nav-menu__list {
  /* Box Model */
  margin : 0;
  padding: 0;

  /* Other */
  list-style-type: none;
}
// .nav-menu-item { }
li[class*="nav-menu-item"] {
  /* Other */
  border-bottom: 1px solid #0002;
  box-shadow   : 0 1px 0 0 #fffa;

  &:last-child {
    /* Other */
    border-bottom: none;
    box-shadow   : none;
  }
}

*[class*='control--'] {
  .nav-menu__list & {
    @extend %button;

    /* Flexbox */
    display    : flex;
    align-items: center;

    /* Box Model */
    width  : 100%;
    padding: var(--base-line-height) var(--base-line-height-halved);

    /* Typography */
    color: var(--color--text-bold);
  }
}

.button__icon {
  .nav-menu__list *[class*='control--'] & {
    /* Box Model */
    margin-left: 0;
    // min-width  : 1.5rem;

    /* Typography */
    font-size: 1.4rem;
  }
}
.nav-menu-item--ubicacion {
  // Flexbox
  display: flex; // Positioning elements

  // Position
  position: relative; // Positioning context
}
.ubicacion__label {
  // Flexbox
  display: flex; // Make sure contents are centered
}
.ubicacion__select {
  @extend %select;

  /**
  | NOTES
  | =====
  | Here we're creating a faux button to trigger
  | the select. We do this by setting the select's
  | opacity and positioning it over and above the
  | label. This means when clicking the fake 'button'
  | the select will be triggered.
  |
  **/

  // Variables
  --qr-button--width: 4.5rem; // Width of control--ubicacion-qr-selector

  /* Position */
  position: absolute;
  top     : 0;
  left    : 0;

  /* Box Model */
  padding: var(--base-line-height-halved);
  // width  : calc(100% - var(--qr-button--width)); // quitamos el boton de QR
  width: 100%;

  /* Other */
  opacity: 0;
}
.control--ubicacion-qr-selector {
  .nav-menu__list & {
    // Flexbox
    display        : flex;
    align-items    : center;
    justify-content: center;

    // Box Model
    width: 4.5rem;

    // Other
    border-left: 1px solid #fffa;
    box-shadow: -1px 0 0 0 #0002;
  }
}
.layout--icon-container {
  .control--ubicacion-qr-selector & {
    // Box Model
    margin: 0;
  }
}
.button__icon {
  .nav-menu__list .control--ubicacion-qr-selector & {
    // Typography
    font-size: 1.6rem;
  }
}
.nav-menu-item--moneda {
  /* Position */
  position: relative; // Positioning context
}
.moneda__label {
  // Flexbox
  display: flex;
}
.label__icon {
  .nav-menu__list [class*='control--'] & {
    /* Box Model */
    margin-left: 0;
    min-width  : 1.5rem;

    /* Typography */
    font-size: 1.4rem;
  }
}
// .label__text { }
.moneda__select {
  @extend %select;

  /**
  | NOTES
  | =====
  | Here we're creating a faux button to trigger
  | the select. We do this by setting the select's
  | opacity and positioning it over and above the
  | label. This means when clicking the fake 'button'
  | the select will be triggered.
  |
  **/

  /* Position */
  position: absolute;
  top     : 0;
  left    : 0;

  /* Box Model */
  padding: var(--base-line-height-halved);

  /* Other */
  opacity: 0;
}
// option {
//   .moneda__select & { }
// }
// .nav-menu-item--usuario { }
.control--logout-and-login {
  .nav-menu__list & {
    // Typography
    color         : var(--color--primary);
    font-weight   : bold;
    letter-spacing: 0.1rem;
  }
}
.button__label {
  .control--logout-and-login & {
    // Typography
    font-size     : 1.3rem;
    text-transform: uppercase;
  }
}
.control--nav-menu-close {
  @extend %button;

  // Box Model
  width  : 100%;
  padding: var(--base-line-height) var(--base-line-height-halved);

  // Typography
  color    : var(--color--accent-shade);
  font-size: 1.4rem;

  // Other
  background-color: #0001;
  border-top      : 1px solid #fffa;
  box-shadow      : 0 -1px 0 0 #0002;
}
.button__icon {
  .control--nav-menu-close & {
    /* Box Model */
    margin-left: 0;
  }
}
.button__label {
  .pibun-logo & {
    font-size: 2rem;
  }
}