.filtros {  
  // Flexbox
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  
  // Box Model
  width     : 100%;
  // height    : calc( 100vh - var(--app-bar--height) );
  height    : calc( 25vh - var(--app-bar--height) );
  overflow-y: scroll;
  
  // Other
  background-color: hsl(40,46%,87%,100%);
  box-shadow      : inset 0 -1px 0px 0px #0001, 
                    0 1px 1px 0 #fff;
  transition      : height 0.5s ease-in-out;


  &.is--collapsed {
    // Position
    top: var(--app-bar--height);

    // Box Model
    height    : 0;
    overflow-x: auto;

    &.has--active-filters {
      // Box Model
      height: calc(var(--base-line-height) * 3);
    }
  }

}
.filtros__list {
  // Box Model
  margin : 0;
  padding: 0;

  // Flexbox
  display       : flex;
  flex-direction: row;
  flex-wrap     : wrap;

  // Other
  list-style-type: none;

  .is--collapsed.has--active-filters & {
    // Flexbox
    flex-wrap: nowrap;

    // Box Model
    height   : 50px;
    width    : max-content;
  }
}
.filtros__item {
  // Box Model
  display: inline-block;
}
.control--filtro {
  .filtros__item & {
    @extend %button;

    // Box Model
    height : calc(var(--base-line-height) * 3);
    margin : 0;
    padding: 0 var(--base-line-height-halved);
  }
}
.button__icon {
  .control--filtro & {
    // Box Model
    margin-left : 0;
    margin-right: var(--base-line-height-halved);

    // Typography
    color: var(--color--accent-shade);

    .is--activo & {
      // Typography
      color: var(--color--main-tint);
    }
  }
}
.button__label {
  .control--filtro & {
    // Box Model
    display: inline-block;
    padding: var(--base-line-height-halved) var(--base-line-height); // [01]

    // Typography
    color    : var(--color--accent-shade);
    font-size: 1.3rem;

    // Other
    border-radius: 1.6rem;
    box-shadow   : inset 0 0 0 2px rgba(var(--color--accent-shade-rgb), 0.5);

    .is--activo & {
      // Typography
      color: var(--color--main-tint);

      // Other
      background-color: var(--color--accent-shade);
      box-shadow      : inset 0 0 0 2px rgba(var(--color--accent-shade), 0.5);
    }
  }
}