.layout--modal {
  position: fixed;
  z-index: 200;
  background-color: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout--modal--card {
  color: var(--color--text-bold);
  max-width: 80vw;
  background-color: var(--color--accent);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal__icon--main {
  color: var(--color--primary);
  font-size: 36px;
  margin-block-end: 20px;
}

.modal__control--single {
  margin-block-start: 14px;
  width: 100%;

  & .button__label {
    width: 100%;
  }
}
.modal__titulo {
  text-align: center;
  font-size: 1.5em;
  line-height: 1.3em;
  font-weight: bold;
}
.modal__texto {
  // font-size: 1.5em;
  line-height: 1.3em;
  text-align: center;
}
.modal__button {
  @extend %button--text-and-icon;
  @include button--default-shadow;

  /* Box Model */
  padding: var(--base-line-height-halved);

  /* Typography */
  color: var(--color--main-tint);

  /* Other */
  background-color: var(--color--primary);
  border-radius   : 0.4rem;

  &:disabled {
    // Typography
    color: var(--color--text-bold);
    // Other
    background-color: transparent;
    box-shadow      : inset 0 0 0 2px var(--color--primary-shade);
    cursor          : not-allowed;
  }

  &.button--secondary {
    // Typography
    color: white;
    // Other
    background-color: var(--color--accent-shade);
    // background-color: hsl(252,10%,45%,100%); -> Based on Independence
  }
}
.button__label {
  .modal__button & {
    // Typography
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}