.dashboard-pedidos {

//  max-width: 200px;
  position: relative;
  //height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.dashboard-pedidos__filtros-row {
  display: flex;
  justify-content: space-evenly;
}

.dashboard-pedidos__pie-container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 100%;
  //width: 100%;
  //margin: auto;
  //max-width: 300px;
}

.dashboard-pedidos__pie-chart {
  width: 100%;
  height: 200px;
}

.dashboard-pedidos__bars-container {

  flex: 2;
  height: 400px;
  //margin: auto;
  //max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.dashboard-pedidos__dias-container {
  position: relative;
  flex: auto;
  width: 100%;
  height: 100px;
}

.dashboard-pedidos__horas-container {
  position: relative;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px
}

.dashboard-pedidos__horas-select {
  position: absolute;
  top: 10px;
  //right: 50%;
  z-index: 100;
}