.layout--base {
  // Flexbox
  display       : flex;
  flex          : 1;
  flex-direction: column;
  // Position
  position: relative;
  // Box Model
  overflow-y: scroll;

}
.main-container {
  // Flexbox
  flex: 1;
  /* Box Model */
  box-sizing: border-box;
  padding   : var(--base-line-height-halved) var(--base--padding);

  /* Other */
  overflow       : hidden;
  overflow-y     : auto;
  scroll-behavior: smooth;

  &.has--items-in-cart {
    // Box Model
    padding-bottom: calc(var(--cta-bar--height) + var(--base-line-height-halved));

  }

  &.has--active-filters {
    // padding-top: calc(var(--app-bar--height) + var(--base-line-height-halved));
  }

  &.has--filters-expanded {
    // padding-top: calc( 25vh - var(--app-bar--height) );
  }

}
