// .carta-de-productos__page-controls { }
.section--carta {
  // Hack para desktop (queda estilo mobile)
  max-width: 500px;
  margin: auto;
}
.control--return-to-menu {
  .carta-de-productos__page-controls & {
    // Box Model
    width        : 100%;
    margin-top   : calc(-1 * var(--base-line-height-halved));
    padding-left : 0;
    padding-right: 0;

    // Typography
    color         : var(--color--accent-shade);
    font-size     : 1.4rem;
    font-weight   : 600;
    text-transform: uppercase;
  }
}
.carta-de-productos__cart-warning {
  // Box Model
  padding      : var(--base-line-height-halved);
  margin-bottom: var(--base-line-height);

  // Typography
  color    : var(--color--accent-shade);
  font-size: 1.4rem;

  // Other
  background-color: #fffa;
}
.warning-text {
  .carta-de-productos__cart-warning & {
    // Box Model
    margin: 0;

    // Typography
    color: var(--color--primary);
  }
}
.icon--warning {
  .carta-de-productos__cart-warning & {
    // Box Model
    margin-right: var(--base-line-height-halved);

    // Typography
    color    : var(--color--primary-shade);
    font-size: 1.6rem;
  }
}
.modal__select {
  .modal--seleccionar-ubicacion & {
    outline: none;
    border: none;
    padding: var(--base-line-height-halved);
    width: 100%;
    font-size: var(--base-font-size);
    border-radius: 4px;
  }
}
.layout--control-container {
  .modal--seleccionar-ubicacion & {
    width: 100%;
    display: flex;
    margin-block-start: var(--base-line-height);
  }
}
.control--confirmar {
  .modal--seleccionar-ubicacion & {
    margin-inline-end: var(--base-line-height-halved);
    flex: 1;
    justify-content: center;
  }
}
.modal__texto {
  .modal--seleccionar-ubicacion & {
    // Box Model
    display           : block;
    margin-block-start: var(--base-line-height);
    width             : 100%;

    span {
      // Position
      position: relative;
      z-index : 10;
      // Box Model
      padding-inline: var(--base-line-height-halved);
      // Typography
      font-size: 14px;
      // Other
      background-color: var(--color--accent);
    }
  }
  .modal--seleccionar-ubicacion &::after {
    content: '';
    // Position
    position: relative;
    top     : -7px;
    // Box Model
    display: block;
    height : 1px;
    width  : 100%;
    // Other
    background-color: var(--color--accent-shade);
  }

}
.pedidos-pendientes {
  // Box Model
  height: calc(var(--base-line-height) * 3);
  // Other
  background-color: var(--color--accent-tint);
}
.control--pedidos-pendientes {
  .pedidos-pendientes & {
    // Reset
    background: none;
    border    : none;
    margin    : 0;
    outline   : none;
    padding   : 0;
    // Flexbox
    display        : flex;
    justify-content: center;
    // Box Model
    height: 100%;
    width : 100%;
    // Other
    box-shadow: inset 0 -3px 1px -1px #0001;
    cursor    : pointer;
  }
}
.leyenda {
  .control--pedidos-pendientes & {
    // Box Model
    margin-block : auto;
    margin-inline: var(--base-line-height-halved);
    // Typography
    color         : var(--color--accent-shade);
    // font-size     : 1.5rem;
    font-weight   : 500;
    text-transform: uppercase;

    .landscape & {
      font-size: 1.5rem;
    }
  }
}
.cantidad {
  .control--pedidos-pendientes & {
    // Typography
    color      : var(--color--primary);
    font-weight: 700;
  }
}
.tiempo {
  .control--pedidos-pendientes & {
    // Position
    position: relative;
    top     : -0.2rem; // Visual positioning due to weird line-height properties
    // Box Model
    margin-block : auto;
    margin-inline: var(--base-line-height-halved);
    // Typography
    color      : var(--color--accent-shade);
    font-size  : 1.5rem;
    font-family: 'Pacifico', cursive;
    line-height: 1.5rem;

    .landscape & {
      font-size: 2rem;
    }
  }
}
.icon--eye {
  .control--pedidos-pendientes & {
    // Box Model
    margin-block : auto;
    margin-inline: var(--base-line-height-halved);
    // Typography
    color    : var(--color--accent-shade);
    font-size: 2rem;
  }
}