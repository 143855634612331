.recepcion {
  // POSITION HACK
  // TODO: Refactor screen padding out of main-container & into div.screen-name
/*
  position: relative;
  top     : calc(var(--base-line-height-halved) * -1);
  left    : calc(var(--base-line-height-halved) * -1);
   */
  // Box Model
  height             : calc(100vh - var(--app-bar--height));
  min-height         : 520px;
  padding-block-start: calc(var(--base-line-height) * 4);
  width              : 100vw;
  // Other
  background-color: var(--color--primary);
}
.layout--app-bar.menu-recepcion {
  background-color: var(--color--main-tint-shade);
  color: var(--color--primary);

  & .control--settings .button__icon {
    color: var(--color--primary);
  }

  & .control--settings .button__label {
    display: none;
  }

  .app-bar__logo,
  .control--settings.qrcode {
    display: flex;
  }
  .pibun-logo {
    color: var(--color--text-bold);
  }
}
.app-bar__controls {
  .menu-recepcion & {
    width: 100%;
  }
}
.recepcion__titulo-container {
  // Flexbox
  display       : flex;
  flex-direction: column;
}
.titulo,
.subtitulo {
  .recepcion__titulo-container & {
    // Box Model
    display      : inline-block;
    margin       : 0;
    margin-inline: auto;
    // Typography
    text-align: center;
  }
}
.titulo {
  .recepcion__titulo-container & {
    @include font-poppins;
    // Typography
    color      : var(--color--text-bold);
    font-size  : 40px;
    font-weight: 500;
    line-height: 43px;

    .highlight {
      // Typography
      color: var(--color--accent);
    }
  }
}
.subtitulo {
  .recepcion__titulo-container & {
    @include font-caveat;
    // Box Model
    margin-block-start: var(--base-line-height);
    // Typography
    color      : white;
    font-size  : 3rem;
    line-height: 2.7rem;
  }
}

.recepcion__pasos {
  // Flexbox
  display        : flex;
  justify-content: center;
  // Box Model
  margin-block-start: calc(var(--base-line-height) * 3);
}
.pasos-list {
  // Reset
  margin : 0;
  padding: 0;
  // List
  list-style-type: none;
}
.paso {
  // Flexbox
  display       : flex;
  flex-direction: row;
  align-items   : center;
  // Box Model
  margin-block-start: var(--base-line-height);

  &:first-child {
    // Box Model
    margin-block-start: 0;
  }
}
.paso__number {
  @include font-pacifico;
  // Flexbox
  display        : flex;
  align-items    : center;
  justify-content: center;
  // Box Model
  // aspect-ratio: 1;
  height      : 3.9rem;
  width       : 3.9rem; // aspect ratio no funciona con padding
  padding-block-end: 5px; // Visual alignment of font in container
  margin-inline-end: calc(var(--base-line-height-halved) * 1.5);
  // Typography
  color      : var(--color--text-bold);
  font-size  : 2.8rem;
  // line-height: 4.6rem; no funciona en flex
  // Other
  background-color: var(--color--accent-tint);
  border-radius   : 50%;
}
.paso__text {
  // Typography
  color      : white;
  font-size  : 1.9rem;
  font-weight: 500;
  line-height: 2.4rem;

  // &.pibun-logo {
  //   @include font-pacifico;
  // }
}
.recepcion__controls {
  // Positioning
  position: fixed;
  bottom  : 17px;
  z-index : 100;
  // Box Model
  width: 100%;
  // Flexbox
  display        : flex;
  justify-content: space-around;
}
.icon-button {
  .recepcion__controls & {
    // Box Model
    height: 45px;
    width: 140px;
    // Flexbox
    justify-content: center;
    // Other
    background-color: var(--color--primary);
    border-radius: 4px;
    color: white;

    & .button__label {
      // Typography
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .landscape &:hover {
      background-color: var(--color--main-tint-shade);
      color: var(--color--primary);
      border: 2px solid var(--color--primary);
    }
  }
}
.recepcion__bottom-art {
  // Positioning
  position: fixed;
  bottom  : 0;
  // Box Model
  height: 135px;
  width : 100%;
  // Background
  background-image     : url($image-path + '/ellipses.svg');
  background-position-x: center;
  background-position-y: top;
  background-size      : 600px auto;
}
.recepcion__bottom-art__1 {
  position: absolute;
  top: 0;
}
.recepcion__bottom-art__2 {
  position: absolute;
  top: 41px;
}
.recepcion__bottom-art__3 {
  position: absolute;
  top: 95px;
}
.recepcion__bottom-art__ellipse {
  position: absolute;
  left: 0;
  top: 0;
  width: 414px;
  height: 265px;
  background: #F5EEE066;
  border-radius: 50%;

  &.right {
    left: 207px;
  }
}