.layout--app-bar {
  /* Flexbox */
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : center;

  /* Box Model */
  height : var(--app-bar--height);
  padding: 0 var(--base-line-height-halved) 0 0;
  z-index: 200; // Making sure the box-shadow shows through

  /* Typography */
  color: var(--color--main-tint);

  /* Other */
  background-color: var(--color--primary);
  box-shadow      : 0 1px 5px -1px var(--color--accent-shade);
}
.session-information__location {
  // TEST RULES
  display: none;
  // TEST RULES
}
// .location { }
.location__business {
  /* Box Model */
  display: inline-block;
}
.location__business--name {
  /* Box Model */
  display: inline-block;
}
.app-bar__controls {
  /* Flexbox */
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
}
.control--settings {
  @extend %button--text-and-icon;
}
.button__icon {
  .control--settings & {
    /* Box Model */
    margin-left: 0;

    /* Typography */
    color    : var(--color--main-tint);
    font-size: 2rem;
  }
}
.app-bar__logo {
  display: none;
}
.control--settings.qrcode {
  display: none;
}
.button__label {
  .control--settings & {
    /* Box Model */
    margin-left: var(--base-line-height-halved);

    /* Typography */
    color         : var(--color--main-tint);
    font-size     : 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    /* Other */
    overflow  : hidden;
    transition: width 350ms ease-out;
  }
  .control--settings.is--furled & {
    /**
        | HIDING
        | ======
        | Using an easy way to hide the label for the button
        | that allows the button itself to rezise to the size of
        | the icon.
        |
        | This could later be upgraded to allow for animations
        | that would make the transition less jarring.
        |
        **/
    /* Position */
    position: absolute;

    /* Other */
    transform: scale(0);
  }
}
.control--filters {
  @extend %button--text-and-icon;

  /* Box Model */
  margin-right: var(--base-line-height-halved);

  /* Position */
  position: relative; // Creating a positioning context

  &::after {
    content: "";

    /* Box Model */
    display: inline-block;
    height : calc(var(--base-line-height) * 2);
    width  : 0.1rem;

    /* Position */
    position: absolute;
    left    : 0;

    /* Other */
    background-color: #fff3;
    box-shadow      : -1px 0 0px 0 #0003;
  }
}
.button__label {
  .control--filters & {
    /* Typography */
    color         : var(--color--main-tint);
    font-size     : 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}
.button__icon {
  .control--filters & {
    /* Typography */
    color: var(--color--main-tint);
  }
}
.wrapper--currency-selector {
  /* Flexbox */
  display       : flex;
  flex-direction: row;
}
.label--currency-selector {
  @extend %label--text-and-icon;

  /* Typography */
  color         : var(--color--main-tint);
  font-size     : 1.1rem;
  letter-spacing: 0.1rem;
  line-height   : var(--base-line-height);
  text-transform: uppercase;
}
.label__icon {
  .label--currency-selector & {
    /* Box Model */
    margin-left: var(--base-line-height-halved);
  }
}
.wrapper--control--currency-selector {
  @extend %wrapper--select;

  /* Variables */
  --arrow--color: var(--color--main-tint);
}
.control--currency-selector {
  @extend %select;

  /* Typography */
  color: var(--color--main-tint);
}
option {
  .control--currency-selector & {
    /* Box Model */
    padding: 0.2rem;
  }
}
