.test-bar {
    /**
    | POSITION & DIMENSIONS
    | =====================
    | 1. We position the element so that it will overlap
    |    with the <.app-bar>
    | 2. We set the width of the bar to 100% minus the
    |    .is--furled width of <.control--settings>
    **/

    /* Variables */
    --bar-width: 150px;
    // MAGIC NUMBER - Based on <.control--settings>

    /* Position */
    position: fixed;
    top     : 0;
    right   : 0;
    z-index : 200;

    /* Flexbox */
    display       : flex;
    flex-direction: row;
    align-items   : center;

    /* Box Model */
    height   : var(--app-bar--height);
    max-width: calc( 100% - var(--bar-width) );
    
}
.test-bar__controls {

}