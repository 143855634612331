/**
TABLE OF CONTENTS
=================
- confirmar-CTA
-   confirmar-CTA__information
-     information__items-in-cart
-       icon--cart
-       items-in-cart__quantity
-     information__total-price
-       total-price__label
-       total-price__price
-         currency-name
-   control--confirmar-pedido
**/
.confirmar-CTA {  
  /* Position */
  position: fixed;
  bottom  : 0;
  left    : var(--base--padding);
  right   : var(--base--padding);
  z-index : 100;
  
  /* Flexbox */
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  
  /* Box Model */
  box-sizing: border-box;
  height    : var(--cta-bar--height);
  padding   : var(--base--padding);
  
  /* Other */
  background-color: var(--color--accent);
  border-radius   : 0.8rem 0.8rem 0 0;
  box-shadow      : 0 0.5rem 0.5rem 0 var(--color--accent-shade),
                    inset 0 1px 0px 0 #fffa;
  transition      : bottom 0.3s ease-out;

  &.is--hidden {
    /* Position */
    bottom: calc( var(--cta-bar--height) * -1 );
  }
}
.confirmar-CTA__information {
  /* Flexbox */
  display       : flex;
  flex-direction: row;
  align-items   : center;
  
  /* Box Model */ 
  margin: 0;

  /* Typography */ 
  color      : var(--color--main-tint);
  font-size  : calc( var(--base-font-size) * 1.25 );
  font-weight: 400;
  line-height: var(--base-line-height);
}
.information__items-in-cart {
  /* Position */
  position: relative; /* Positioning context */

  /* Box Model */
  margin: 0 1.5rem 0 0; // TEST VALUES
}
.icon--cart {
  .information__items-in-cart & {
    /* Typography */
    color    : var(--color--primary-shade);
    font-size: 2.5rem;
  }
}
.items-in-cart__quantity {
  /* Flexbox */
  display        : flex;
  justify-content: center;
  align-items    : center;

  /* Position */
  position: absolute;
  top     : -0.8rem;
  right   : -0.8rem;

  /* Box Model */
  height: 1.9rem;
  width : 1.9rem;

  /* Typography */
  color    : var(--color--main-tint);
  font-size: 1.2rem;

  /* Other */
  background-color: var(--color--accent-shade);
  border-radius   : 50%;
}
.information__total-price {
  /* Flexbox */
  display    : flex;
  align-items: center;

  /* Typography */
  color      : var(--color--accent-shade);
  font-size  : 1.8rem;
  line-height: 2.2rem;
}
.total-price__label {
  /* Box Model */
  margin-right: 0.4rem;
  
  /* Typography */
  font-size  : 1.5rem;
  font-weight: 400;
}
.total-price__price {
  /* Typography */
  font-weight: 700;
}
.currency-name {
  .total-price__price & {
    /* Box Model */
    margin-left: 0.2rem;

    /* Typography */
    font-size  : 1.3rem;
    font-weight: 400;
  }
}
.control--confirmar-pedido {
  @extend %button--text-and-icon;
  @include button--default-shadow;

  /* Box Model */
  padding: var(--base-line-height-halved);

  /* Typography */
  color: var(--color--main-tint);

  /* Other */
  background-color: var(--color--primary);
  border-radius   : 0.4rem;
}
.button__label {
  .control--confirmar-pedido & {
    /* Typography */
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}