// =================
// General Usability
// =================

// Paths
$image-path: '/img';

%hidden {
  // Position
  z-index : -100;
  position: absolute;
  // Scale
  transform: scale(0);
}
// ===============
// Material Design
// ===============
%material-design--shadow {
  // Variables
  --shadow-rgb: var(--color--accent-shade-rgb);
  // Box Shadow
  box-shadow: 0px 2px 1px -1px rgba(var(--shadow-rgb), 0.2),
              0px 1px 1px 0px rgba(var(--shadow-rgb), 0.4),
              0px 1px 3px 0px rgba(var(--shadow-rgb), 0.1);
}