// Poppins @ Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,700&display=swap');

// Caveat @ Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600;700&display=swap');

// Pacifico @ Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600;700&family=Pacifico&display=swap');

@mixin font-poppins($size: 16px, $line-height: 1.375) {
    font-family: 'Poppins', sans-serif;
    font-size  : $size;
    line-height: $line-height;
}

@mixin font-caveat($size: 30px, $line-height: 0.9) {
    font-family: 'Caveat', cursive;
    font-size  : $size;
    line-height: $line-height;
}

@mixin font-pacifico($size: 24px, $line-height: 1) {
    font-family: 'Pacifico', cursive;
    font-size  : $size;
    line-height: $line-height;
}