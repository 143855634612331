.screen--admin {

  --admin-nav--width: 200px;
  --admin-title--height: calc(var(--base-line-height) * 2 + 12px);

  position: relative;
  height: calc(100vh - var(--app-bar--height));

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  font-size: 14px;

  .table {
    border: 1px solid black;
    font-size: 16px;
    border-spacing: 0;
    border-collapse: collapse;

    td {
      padding: var(--base-line-height);
    }

    th {
      vertical-align: bottom;
    }
  }

  .controles {
    padding: 14px;
  }
}

tr {
  .screen--admin *[disabled] tbody & {
    cursor: not-allowed;
  }
}

.admin-navegacion {
  // Position
  position: relative;
  // top     : var(--app-bar--height);

  // Box Model
  height: 100%;

  // Other
  overflow: hidden;
  overflow-y: scroll;
}

.admin-contenido {
  // Position
  position: relative;
  // top: calc(var(--app-bar--height) + var(--admin-title--height));
  top: var(--admin-title--height);

  // Box Model
  padding   : 0 var(--base-line-height) var(--base-line-height-halved);
  height    : 100%;
  max-height: calc(100% - var(--admin-title--height));

  flex: 1;

  overflow: scroll;
}

.admin-title {
  // Position
  position: fixed;
  top     : var(--app-bar--height);
  left    : var(--admin-nav--width);

  // Box Model
  // height : var(--admin-title--height);
  width  : 100%;
  padding: var(--base-line-height) var(--base-line-height) calc(var(--base-line-height-halved) * 1.5);

  // Typography
  color         : #666;
  letter-spacing: 1px;
  text-indent   : 1px; // Helps visual alignment with screen title
}

.admin-title__title {
  padding: 0;
  margin : 0;
}

.admin-title__comercio {
  // Box Model
  margin : 0;
  padding: 0;

  // Typography
  color: var(--color--accent-shade);
}

.table {
  .admin-contenido & {
    // Table
    border         : none;
    border-collapse: separate;
    border-spacing : 0 var(--base-line-height-halved);

    // Other
    border: none;
  }
}

.table-row:not(.table-row--thead) {
  .admin-contenido & {
    // @extend %material-design--shadow;
    // Other
    border-radius: 0.5rem;

    &:hover {
      @extend %material-design--shadow;
      // Other
      /* box-shadow   : -6px 0 0 0 var(--color--primary-shade),
                      0 0 6px -1px #3336; */
    }
  }
}
.table-header-cell {
  .admin-contenido & {
    // Box Model
    padding: var(--base-line-height-halved) var(--base-line-height);
    // Typography
    color      : var(--color--accent-shade);
    font-size  : 1.7rem;
    font-weight: 600;
    text-align : left;
  }
}
.table-data-cell {
  .admin-contenido & {
    // Other
    background-color: #fffe;
    border          : none;
  }
  .admin-contenido .table-row:hover &:first-child {
    // Other
    box-shadow: inset 2px 0 0 0 var(--color--primary-shade);
  }
  .admin-contenido .table-row:hover &:last-child {
    // Other
    box-shadow: inset -2px 0 0 0 var(--color--primary-shade);
  }
  .admin-contenido &:first-child {
    // Other
    border-radius: 5px 0 0 5px;
  }
  .admin-contenido &:last-child {
    // Other
    border-radius: 0 5px 5px 0;
  }
}
.row-controls--block {
  .admin-contenido & {
    // Flexbox
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
  }
}

.admin-button {
  .row-controls--block &:not(:last-child) {
    margin-block-end: calc(var(--base-line-height) / 3);
  }
}

// TODO Refactor these rules into their own file
.admin-button {
  // .admin-contenido .table & {
    // Variables
    --button-color: var(--color--accent-shade);
    --button-background: none;
    --hover-color: #eee;
    --shadow-color: #aaa;
    // Box Model
    padding: var(--base-line-height-halved) var(--base-line-height);

    // Typography
    color         : var(--button-color);
    text-transform: uppercase;

    // Other
    background   : var(--button-background);
    border       : none;
    border-radius: 4px;
    box-shadow   : 0 0 0 1px var(--shadow-color);
    cursor       : pointer;

    &:hover {
      // Other
      background-color: var(--hover-color);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--shadow-color);
    }
  // }
}
.screen-title { //.screen-title
  .admin-contenido & {
    // Flexbox
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // Box Model
    margin : 0 0 var(--base-line-height);
    padding: 0 0 var(--base-line-height-halved);

    // Typography
    color         : var(--color--accent-shade);
    font-weight   : 600;
    // letter-spacing: 1px;

    // Other
    box-shadow: 0 2px 0 0 var(--color--accent);
  }
}
.screen-title__controls {
  .admin-contenido & {
    display: flex;
    flex-direction: row;
  }
}
.formulario {
  .admin-contenido & {
    // Flexbox
    display: flex;
    flex-direction: column;
  }
}
.form__title {
  .admin-contenido & {
    // Typography
    color      : var(--color--accent-shade);
    font-size  : 1.8rem;
    font-weight: 600;
    line-height: 2.1rem;
  }
}
.form__row {
  .admin-contenido & {
    // Flexbox
    display       : flex;
    flex-direction: row;
    justify-content : flex-start;
    // Box Model
    margin: 0;
    margin-block-end: calc(var(--base-line-height) * 2);
  }
}
.form__col {
  .admin-contenido & {
    // Flexbox
    display       : flex;
    flex-direction: column;
    justify-content : flex-start;
    // Box Model
    margin: 0;
    margin-block-end: calc(var(--base-line-height) * 2);
  }
}

/* References:
- https://dribbble.com/shots/10767455-Dashboard-Redesign-Project
- https://dribbble.com/shots/6559902-Untill-hospitality-management-platform-Reports
- https://dribbble.com/shots/5315078/attachments/5315078-Tables */