.historial__title {
  // Box Model
  margin : 0 0 var(--base-line-height);
  padding: 0 0 var(--base-line-height-halved);

  // Typography
  color      : var(--color--accent-shade);
  font-size  : calc(var(--base-font-size) * 1.35);
  line-height: calc(var(--base-line-height) * 1.35);

  // Other
  box-shadow: 0 0.1rem 0 0 var(--color--primary-shade);
}