.tarjeta-de-producto--in-cart {
  @extend %material-design--shadow;

  // Flexbox
  display       : flex;
  flex-direction: row;

  // Box Model
  margin : 0 0 var(--base-line-height);
  // padding: var(--base-line-height-halved);

  // Other
  background-color: white;
  border-radius   : 0.8rem;
  overflow        : hidden;
}
.tarjeta-de-producto--in-cart__information-column {
  // Flexbox
  flex: 1;

  // Box Model
  padding: var(--base-line-height-halved);
}
.tarjeta-de-producto--in-cart__name {
  // Box Model
  margin : 0 0 calc(var(--base-line-height-halved) / 2);
  padding: 0 0 calc(var(--base-line-height-halved) / 2);

  // Typography
  color         : var(--color--text-bold);
  font-size     : var(--base-font-size);
  font-weight   : 700;
  text-transform: uppercase;

  // Other
  box-shadow: 0 0.1rem 0 0 var(--color--main-tint-shade);
}
.tarjeta-de-producto--in-cart__meta {
  // Flexbox
  display       : flex;
  flex-direction: row;
}
.tarjeta-de-producto--in-cart__controls {
  /**
  [1]: The controls are positioned flush with the card. This helps
  us bridge the difference between the button sizes and the visual
  representation of said buttons. The objective is to line up the
  visuals with the title of the item. */

  // Flexbox
  display       : flex;
  flex-direction: row;

  // Position
  position: relative;
  left    : calc(var(--base-line-height-halved) * -1); // [1]
}
.control--remove-from-cart {
  .tarjeta-de-producto--in-cart__controls & {
    // Flexbox
    justify-content: flex-end;

    // Box Model
    margin: 0;
  }
}
.control--add-to-cart {
  .tarjeta-de-producto--in-cart__controls & {
    // Flexbox
    justify-content: flex-start;

    // Box Model
    margin: 0;
  }
}
.wrapper--icon {
  .tarjeta-de-producto--in-cart & {
    // Variables
    --wrapper-size : 3.2rem;
    --wrapper-width: 3.8rem;

    // Box Model
    min-width: var(--wrapper-width);

    // Other
    border    : 2px solid var(--color--primary);
    box-shadow: none;
  }
  .tarjeta-de-producto--in-cart .control--remove-from-cart & {
    // Other
    border-right : 0;
    border-radius: calc(var(--wrapper-width) / 2)
                   0
                   0
                   calc(var(--wrapper-width) / 2);
  }
  .tarjeta-de-producto--in-cart .control--add-to-cart & {
    // Other
    border-left  : 0;
    border-radius: 0 50% 50% 0;
    border-radius: 0
                   calc(var(--wrapper-width) / 2)
                   calc(var(--wrapper-width) / 2)
                   0;
  }
}
.tarjeta-de-producto--in-cart__wrapper-quantity {
  // Flexbox
  display        : flex;
  align-items    : center;
  justify-content: center;

  // Box Model
  height: calc( var(--base-line-height) * 3);
  width : calc( var(--base-line-height) * 2)
}
.tarjeta-de-producto--in-cart__quantity {
  // Box Model
  margin: 0;
  width : 100%;

  // Typography
  color      : var(--color--accent-shade);
  font-size  : 1.8rem;
  line-height: 3.2rem; // line-height = to wrapper--icon
  text-align : center;

  // Other
  box-shadow: inset 0 0.2rem 0 0 var(--color--primary),
              inset 0 -0.2rem 0 0 var(--color--primary);
}
.control--remove-all-from-cart {
  .tarjeta-de-producto--in-cart__controls & {
    // Typography
    color: var(--color--accent-shade);
    font-size: 1.5rem;
  }
}
.button__label {
  .tarjeta-de-producto--in-cart__controls .control--remove-all-from-cart & {
    // Other
    box-shadow: 0 0.1rem 0 0 var(--color--accent-shade);
  }
}
.tarjeta-de-producto--in-cart__price-column {
  // Flexbox
  flex           : 0.5;
  flex-direction : column;
  display        : flex;
  justify-content: center;
  align-items    : center;

  // Box Model
  padding: var(--base-line-height-halved);

  // Other
  background-color: var(--color--main-tint-shade);
}
.tarjeta-de-producto--in-cart__total-price {
  // Variables
  $font-size  : 1.7rem;
  $line-height: 1.7rem;

  // Position
  position: relative;

  // Box Model
  display       : inline-block;
  margin        : 0 0 0 0.2rem;
  padding-bottom: calc(var(--base-line-height-halved)/2);
  margin-bottom : calc(var(--base-line-height-halved)/2);
  width         : 100%;

  // Typography
  color      : var(--color--accent-shade);
  font-size  : $font-size;
  font-weight: bold;
  line-height: $line-height;
  text-align : center;

  // Other
  box-shadow: 0 0.1rem 0 0 var(--color--primary-shade);
}
// .tarjeta-de-producto--in-cart__information--price { }
.tarjeta-de-producto--in-cart__unit-price {
  // Box Model
  margin: 0;
}