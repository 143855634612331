.table--precios {
}

.precio-combo {
  display: flex;
}

.precio-combo__input {
  width: 5em;

  .has-changed & {
    background-color: yellow;
  }
}

.icon-button.precio-combo__cancel {
  margin-left: 10px;
  visibility: hidden;

  .has-changed & {
    visibility: visible;
  }
}

.column-heading {
  .table--precios & {
    // Flexbox
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    // Box Model
    margin-block-end : var(--base-line-height-halved);
    padding-block-end: var(--base-line-height-halved);
    // Other
    border-block-end: 1px solid #6666;
  }
}
.column-title {
  .table--precios & {
  }
}
.control--hide {
  .admin-contenido .table .column-heading & {
    // Box Model
    margin-inline-start: 4px;
    padding: 4px;
    // Typography
    color      : #666;
    font-size  : 14px;
    line-height: 14px;
    // Other
    background: transparent;
    box-shadow: none;

    &:hover {
      color: var(--color--primary);
    }
  }
}
.column-meta {
  .table--precios & {
    // Flexbox
    display       : flex;
    flex-direction: row;
    align-items   : center;
  }
}
.column-meta__data {
  .table--precios & {
    // Flexbox
    flex: 1;
    // Typography
    font-weight: 400;
    font-size  : 14px;
  }
}
.moneda {
  .column-meta__data & {
    // Box Model
    display       : inline-block;
    padding-block : 4px;
    padding-inline: 6px;
    // Other
    background-color: #eee;
    border-radius   : 4px;
    box-shadow      : inset 0 0 0 1px #999;
  }
}
.column-meta__controles {
  .table--precios & {
    // Flexbox
    display       : inline-flex;
    flex-direction: row;
    // Box Model
    padding: 0;
  }
}
button {
  .admin-contenido .table .column-meta__controles &:not(:last-child) {
    margin-inline-end: var(--base-line-height-halved);
  }
}
.control--activate {
  .admin-contenido .table .column-meta__controles & {
    // Variables
    --height: 24px;
    --width : 40px;
    // Position
    position: relative;
    // Box Model
    height : var(--height);
    width  : var(--width);
    padding: 0;
    // Other
    background-color: #999;
    border-radius   : calc(var(--height) / 2);
    box-shadow      : inset 0 1px 1px 0 #555, 
                      0 1px 0 1px white;
  }
  .admin-contenido .table .column-meta__controles &:before {
    content: '';

    // Variables
    --margin: 2px;
    --dimensions: 20px;
    // Position
    position: absolute;
    left    : var(--margin);
    top     : var(--margin);
    z-index : 20;
    // Box Model
    display: block;
    height : var(--dimensions);
    width  : var(--dimensions);
    // Other
    background-color: #eee;
    border-radius   : 50%;
    box-shadow      : 0 2px 3px -1px #333;
    transition      : .3s ease-out;
  }
  .admin-contenido .table .column-meta__controles &.is--active {
    // Other
    background-color: var(--color--primary);
  }
  .admin-contenido .table .column-meta__controles &.is--active:before {
    // Position
    left: 18px;
  }
}