.tarjeta-de-producto {
  @extend %material-design--shadow;

  /* Variables */
  --menu__item--height: calc(var(--base-line-height) * 8);

  /* Flexbox */
  display       : flex;
  flex-direction: row;

  /* Box Model */
  margin: 0 0 var(--base-line-height);
  height: var(--menu__item--height);

  /* Other */
  background-color: white;
  border-radius   : 0.8rem;
  overflow        : hidden;

  &.is--expanded {
    /* Flexbox */
    flex-direction: column;

    /* Box Model */
    height: calc(var(--menu__item--height) * 3);
  }
  li:last-child & {
    /* Box Model */
    margin: 0;
  }
}
.tarjeta-de-producto__meta {
  /* Variable */
  --width: 2rem;

  /* Flexbox */
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: flex-end;

  /* Flexbox-Child */
  flex : 0 0 var(--width);
  order: 1;

  /* Box Model */
  max-width: var(--width);
  /**
  | MAGIC NUMBERS
  | =============
  | We want .tarjeta-de-producto__meta to have a maximum side padding of
  | 1rem, and we *also* need to:
  | 1) Ensure we fit the widest 2-digit number.
  | 2) Leave enough space for the ::after 'x'.
  **/
  padding: var(--base-line-height) 0.8rem var(--base-line-height) 0.2rem;

  /* Other */
  background-color: var(--color--main-tint-shade);
  color           : #aaa;

  .is--in-cart & {
    // Typography
    color: var(--color--main-tint);
    // Other
    background-color: var(--color--primary-shade);
  }

  .is--expanded & {
    /* Flexbox */
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-end;

    /* Flexbox-Child */
    order: 3;

    /* Box Model */
    max-width: 100%;
    padding  : var(--base-line-height-halved);

    // Typography
    color: var(--color--accent-shade);

    /* Other */
    background-color: transparent;
    box-shadow      : inset 0 0.1rem 0 0 #ddd, inset 0 0.2rem 0 0 #fafafa;
  }
}
.tarjeta-de-producto__meta--cart {
  /**
    | Other
    | =====
    | Set the default visibility to 'collapse' so we can
    | determine the conditions in which we show this by
    | using class selectors.
    **/
  visibility: collapse;

  .is--in-cart &,
  .is--expanded & {
    /* Other */
    visibility: visible;
  }

  .is--expanded & {
    /* Flexbox */
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;

    /* Flexbox-Child */
    flex: 1;

    /* Typography */
    font-size: 2.2rem;
  }
}
.icon--cart {
  .tarjeta-de-producto__meta--cart & {
    /**
      | FIXES
      | =====
      | 1) We push the icon 2px from the left to solve the
      |    fact that, visually, it appears flushed to the
      |    left of the container, even with 2px of padding.
      |
      **/

    /* Position */
    position: relative; /* 1) */
    left    : 2px; /* 1) */
  }
}
.tarjeta-de-producto__meta--cart-amount {
  /* Position */
  position: relative;

  /* Box Model */
  margin: 0;

  /* Typography */
  font-weight   : 600;
  font-size     : 1.1rem;
  letter-spacing: 0;
  text-align    : center;
  text-shadow   : 0 0 1px #000a;

  .is--expanded & {
    /* Variables */
    $font-size  : 1.8rem;
    $line-height: 2.2rem;

    /* Box Model */
    display: inline-block;
    margin : 0 0 0 calc(#{$font-size} / 2);

    /* Typography */
    font-size     : $font-size;
    font-weight   : 900;
    line-height   : $line-height;
    letter-spacing: 0.1rem;
    text-shadow   : none;
  }

  &::after {
    content: "x";

    /* Position */
    position: absolute;

    /* Box Model */
    display: inline-block;
    margin : 0 0 0 0.1rem;

    .is--expanded & {
      content: "";

      /* Typography */
      font-size: 0;
    }
  }
}
.tarjeta-de-producto__meta--cart-price {
  /* Position */
  position: absolute;

  /* Other */
  transform: scale(0);

  .is--expanded & {
    /* Variables */
    $font-size  : 1.4rem;
    $line-height: 1.6rem;

    /* Position */
    position: relative;

    /* Box Model */
    display: inline-block;
    margin : 0 0 0 0.2rem;

    /* Typography */
    font-size  : $font-size;
    line-height: $line-height;

    /* Other */
    transform: scale(1);
  }
}
.tarjeta-de-producto__meta--price-divider {
  /* Position */
  position: relative;
  top     : -0.05rem;

  /* Box Model */
  margin-right: 0.2rem;
}
// .tarjeta-de-producto__meta--price-per-unit { }
.tarjeta-de-producto__meta--total-price {
  /* Position */
  position: relative;
  top     : 0.1rem;

  /* Box Model */
  margin-left: 0.3rem;

  /* Typography */
  font-size: 1.7rem;

  &::before {
    content: "(";
  }
  &::after {
    content: ")";
  }
}
.currency-name {
  .tarjeta-de-producto__meta--total-price & {
    /* Box Model */
    margin-left: 0.2rem;

    /* Typography */
    font-size: 1.3rem;
  }
  .tarjeta-de-producto__price & {
    /* Box Model */
    margin-left: 0.2rem;

    /* Typography */
    font-size  : 1.2rem;
    font-weight: 400;
  }
}
.tarjeta-de-producto__information {
  /* Flexbox */
  display        : flex;
  flex-direction : column;
  justify-content: space-between;

  /* Flexbox-Child */
  flex : 1 1;
  order: 2;

  /* Box Model */
  padding: var(--base-line-height) var(--base-line-height-halved);

  .is--expanded & {
    /* Flexbox-Child */
    order: 2;
  }
}
.information__row {
  /* Flexbox */
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
}
// .tarjeta-de-producto__information--text {}
.tarjeta-de-producto__name {
  /* Box Model */
  margin : 0 0 calc(var(--base-line-height-halved) / 2);
  padding: 0 0 calc(var(--base-line-height-halved) / 2);

  /* Typography */
  color         : var(--color--text-bold);
  font-size     : var(--base-font-size);
  font-weight   : 700;
  text-transform: uppercase;

  /* Other */
  box-shadow: 0 0.1rem 0 0 var(--color--main-tint-shade);
}
.tarjeta-de-producto__description {
  /* Box Model */
  margin  : 0 0 var(--base-line-height-halved);
  overflow: hidden;

  /**
  | Typography
  | ----------
  | We truncate multiline texts just using CSS.
  | Current support @ https://caniuse.com/?search=webkit-line-clamp
  | Alternatives @ https://css-tricks.com/line-clampin/
  **/
  display           : -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow     : ellipsis;
}
.tarjeta-de-producto__information--price {
  /* Flexbox */
  display        : flex;
  flex-direction : row;
  justify-content: space-between;

  .is--expanded & {
    /* Other */
    transform: scale(0);
  }
}
.tarjeta-de-producto__price {
  /* Flexbox */
  flex: 1;

  /* Box Model */
  margin: 0;

  /* Typography */
  color      : var(--color--text-bold);
  font-size  : 1.4rem;
  font-weight: 700;
}
.tarjeta-de-producto__cart-controls {
  /* Flexbox-Child */
  order: 3;

  /* Flexbox */
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;

  /* Box Model */
  padding: calc(var(--base-line-height-halved) / 2) 0;

  /**
  | HIDING & SHOWING
  | ================
  | We hide the cart controls while the <.menu-item>
  | isn't expanded.
  **/
  position : absolute;
  transform: scale(0);

  .is--expanded & {
    /**
    | HIDING & SHOWING
    | ================
    | We reveal the cart controls when the <.menu-item>
    | is expanded.
    **/
    position : relative;
    transform: scale(1);
  }
}
.control--add-to-cart,
.control--remove-from-cart {
  /* Variables */
  --button-size: calc(var(--base-line-height) * 3);
  --font-size  : 1.5rem;

  /* Reset */
  border : none;
  outline: none;
  margin : 0;
  padding: 0;

  /* Flexbox */
  display        : flex;
  justify-content: center;
  align-items    : center;

  /* Box Model */
  margin    : 0 calc(var(--base-line-height-halved) / 2);
  min-height: var(--button-size);
  min-width : var(--button-size);

  /* Typography */
  color    : var(--color--primary);
  font-size: var(--font-size);

  /* Other */
  background-color: transparent;
  border-radius   : 50%;
}
// .control--add-to-cart { }
// .control--remove-from-cart { }
.wrapper--icon {
  /* Variables */
  --wrapper-size: 2.6rem;

  /* Flexbox */
  display        : flex;
  justify-content: center;
  align-items    : center;

  /* Box Model */
  min-height: var(--wrapper-size);
  min-width : var(--wrapper-size);

  /* Other */
  background-color: transparent;
  border-radius   : 50%;
  box-shadow      : 0 0 0 0.2rem var(--color--primary);
}
.tarjeta-de-producto__image {
  /* Flexbox-Child */
  flex : 0 0 calc(var(--menu__item--height) * 0.75);
  order: 3;

  /**
    | Background-Image
    | ----------------
    | The .tarjeta-de-producto__image element will have a background-image
    | property set inline by JS that will point to the correct
    | src for the product images.
    **/
  background-position: center;
  background-size    : cover;

  .is--expanded & {
    /* Flexbox-Child */
    flex : 1;
    order: 1;
  }
}
.affordance--can-compress,
.affordance--can-expand {
  /* Box Model */
  padding: 0 var(--base-line-height-halved);

  /* Typography */
  font-size: 1.5rem;
}
.affordance--can-expand {
  .is--expanded & {
    /**
        | HIDING
        | ======
        | We hide the element by absolutely positioning it
        | to remove it from the flow of the page and
        | setting its scale to 0.
        **/

    /* Position */
    position: absolute;

    /* Other */
    transform: scale(0);
  }
}
.affordance--can-compress {
  /**
    | HIDING
    | ======
    | We hide the element by absolutely positioning it
    | to remove it from the flow of the page and
    | setting its scale to 0.
    **/

  /* Position */
  position: absolute;

  /* Other */
  transform: scale(0);

  .is--expanded & {
    /* Position */
    position: relative;

    /* Other */
    transform: scale(1);
  }
}
