.admin-linksQR {

}

.table--ubicaciones {
  .admin-linksQR & {
    min-width: 200px;
    margin-right: 40px;
  }
}

.is--selected {
  .admin-linksQR & {
    background-color: red;
  }
}

.form__row {
  .admin-linksQR & {
    .portrait & {
      flex-wrap: wrap;
    }
    // justify-content: center;
  }
}

.control--copiar {
  .admin-linksQR & {
    background-color: var(--color--accent);
  }
}

.warning {
  .admin-linksQR & {
    font-weight: bold;
    color: var(--color--primary);
  }
}

.etiquetas-title {
  .admin-linksQR & {
    padding: 16px;
  }
}

.tags--todos {
  .admin-linksQR & {
  }
}

.filtros {
  .admin-linksQR & {
    background: none;
    height: auto;
    box-shadow: none;
    padding-block: 12px;
  }
}

.producto--tags {
  .admin-linksQR & {
    padding-block: 12px;
  }
}
