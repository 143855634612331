.notificaciones {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 300;
  background-color: var(--color--accent);
  position: fixed;
  width: 100%;
}

.notificaciones__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.notificaciones__item {
  margin-block: 10px;
}

.notificaciones__dismiss-control {
  padding: 6px;
  border: 1px solid var(--color--primary);
  border-radius: 4px;
  margin-bottom: 10px;
}