// table[class*="table--"] {}

.table-row--tbody {

  &.table-row__selectable {
    cursor: pointer;
  }

  .disabledOnSeleccion.has--items-selected & {
    display: none;

    &.is--selected {
      display: table-row;
    }
  }
}
