%button {
    // Box Model
    padding   : 0;

    // Typography
    line-height: var(--base-line-height);

    // Other
    background: none;
    border    : none;
    outline   : none;
    cursor    : pointer;

    &--text-and-icon {
        @extend %button;

        /* Flexbox */
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        align-items    : center;

        /* Box Model */
        height : calc( var(--base-line-height-halved) * 5 );
        padding: 0 var(--base-line-height-halved);
    }
}

.icon-button {
    @extend %button;

    // Variables
    --font-size  : var(--base-font-size);
    --line-height: var(--base-line-height);

    // Flexbox
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;

    // Box Model
    padding: var(--base-line-height) var(--base-line-height-halved);

    &.is--invisible {
        visibility: hidden;
    }
}

.button__label {
    /* Typography */
    font-size  : var(--base-font-size);
    line-height: var(--base-line-height);

    .icon-button & {
        /* Typography */
        font-size  : var(--font-size);
        line-height: var(--line-height);
    }
}

.layout--icon-container {
    // Flexbox
    display        : inline-flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;

    // Box Model
    height: var(--base-line-height);
    margin: 0 var(--base-line-height-halved) 0 0;
    min-width: 18px;

    .icon-position--right & {
        // Flexbox
        order: 2;

        // Box Model
        margin: 0 0 0 var(--base-line-height-halved);
    }

    .no-label & {
        // Box Model
        margin: 0 0 0 0;
    }
}

// .button__icon { }

@mixin button--default-shadow {
    /* Variables */
    --shadow-rgb: var(--color--accent-shade-rgb);

    /* Other */
    box-shadow: rgba(var(--shadow-rgb), 0.2) 0px 3px 1px -2px,
                rgba(var(--shadow-rgb), 0.14) 0px 2px 2px 0px,
                rgba(var(--shadow-rgb), 0.12) 0px 1px 5px 0px;
}