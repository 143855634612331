.pedidos__acciones {
  display: flex;
}
.control__estado {
  display: flex;
  flex-direction: column;
}
.control__avanzar-estado {
  height: 45px;
}
.control__selector-estado {

}